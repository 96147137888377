import { useState, useEffect } from 'react';
import { getCookie, setCookie } from '../libs/cookies';
import { useExperiments } from './useExperiments';

const cookieName = 'dd';

export const useDueDateExperiment = () => {
  const [offset, setOffset] = useState(5);
  const experiments = useExperiments();

  useEffect(() => {
    let variant = getCookie(cookieName);

    if (!variant || (variant && experiments.dueDate && variant !== experiments.dueDate)) {
      variant = experiments.dueDate;

      if (variant) {
        setCookie(cookieName, variant);
      }
    }

    setOffset(calculateDueDateOffset(variant));
  }, [experiments]);

  return offset;
}

const calculateDueDateOffset = (variant) => {
  if (variant === 'end_of_month') {
    return 0;
  } else if (variant === 'five_days_before_end_of_month') {
    return -5;
  } else {
    return 5;
  }
};
