import httpClient from './libs/httpClient';

export const getEkomiFeedback = () => httpClient.get('/api/feedback/average');
export const getBlogPosts = () => httpClient.get('/blog/api/get_recent_posts/');
export const validatePromoCode = (code) => httpClient.post('/api/promo_codes/validate', { code });
export const applyPromoCode = (code) => httpClient.post('/api/promo_codes/apply', { code });
export const getUser = () => httpClient.get('/api/user?embed=debit_card,bank_account,address');
export const getZeroInterestsForNewUsersFlag = () =>
  httpClient.get('api/features/enable_zero_interests_for_new_users');
export const createPixel = (bodyParams) => httpClient.post('/api/pixels/create', bodyParams);
export const startExperiments = (visitorId) =>
  httpClient.post('/api/experiments/start', {}, { headers: { 'Field-Test-Visitor': visitorId } });
